import Select from 'react-select';
import { ErrorMessage, Field } from 'formik';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import inputStyles from './Inputs.module.scss';

export const InputSelect = ({
  className,
  name,
  label,
  placeholder,
  required,
  options,
  error,
  isSearchable,
  isMulti,
  isDisabled,
  onValueChangedCallback,
}) => {
  const convertValue = value => {
    if (!value || value === '') return [];

    if (Array.isArray(value)) {
      return value.map(valueItem => ({
        value: Number.isNaN(Number(valueItem)) ? valueItem : parseInt(valueItem, 10),
        label: Number.isNaN(Number(valueItem))
          ? options?.find(option => option.label === valueItem)?.label
          : options?.find(option => option.value === parseInt(valueItem, 10))?.label,
      }));
    }

    return [
      {
        value,
        label: options?.find(option => option.value === parseInt(value, 10))?.label,
      },
    ];
  };

  const getChildValues = (value, values) => {
    const newValue = isMulti ? value : [value];
    const selectedValues = !newValue.length ? values[name] : newValue.map(val => val.value);
    return options.find(option => selectedValues.includes(option.value))?.childValues;
  };

  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
      style={{ maxWidth: '30vw' }}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <Field name={name}>
          {({ field: { value }, form: { values, setFieldValue } }) => (
            <Select
              className={`${error ? inputStyles.error : ''} kwello-input-select`}
              value={convertValue(value)}
              onChange={val => {
                if (getChildValues(val, values)) {
                  onValueChangedCallback({
                    selectedParents: isMulti ? val.map(v => v.value) : val.value,
                    parentId: name,
                    existingValues: values,
                    setFormValue: setFieldValue,
                  });
                }
                setFieldValue(name, isMulti ? val.map(v => v.value) : val.value);
              }}
              options={sortBy(
                options.map(option => ({
                  value: option.value,
                  label: option.label,
                })),
                'label',
              )}
              isSearchable={isSearchable}
              isMulti={isMulti}
              isDisabled={isDisabled}
              closeMenuOnSelect={!isMulti}
              placeholder={placeholder}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                input: base => ({
                  ...base,
                  padding: '0.26rem',
                }),
                control: base => ({
                  ...base,
                  border: '1px solid rgba(70, 56, 51, 0.15)',
                  boxShadow: 'none',
                  '&:hover': {
                    border: '1px solid rgba(70, 56, 51, 0.15)',
                  },
                }),
              }}
              isClearable={false}
              closeMenuOnScroll={scroll => !scroll?.target?.className?.includes('css-')}
            />
          )}
        </Field>
        <ErrorMessage name={name}>
          {message => <span className={inputStyles.error}>{message}</span>}
        </ErrorMessage>
        {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
      </label>
    </div>
  );
};

InputSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  onValueChangedCallback: PropTypes.func,
};
