import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useSlideDrawer } from 'context/slideDrawerContext';
import { useUser } from 'context/userContext';

import ROUTE from 'constants/route';
import { formatDate } from 'utils/dateHelper';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { OnOffTopicSelector } from 'common';
import Button from 'common/button/Button';
import DateDropdown from 'common/dateDropdown/DateDropdown';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import Icon from 'common/Icon/Icon';
import SearchDropDown from 'common/search/SearchDropDown';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import { SavedSearchModal } from 'Pages/Lists/Modals/SavedSearchModal';

import './searchBar.scss';
import styles from './SearchBar.module.scss';

import { FiltersMenu } from './FiltersMenu';
import { UpdateFilters } from './UpdateFilters';

export const MainFiltersContainer = ({ showSaveSearch = false, className }) => {
  const { socialProjects } = useUser();
  const { projectId } = useParams();

  const { openSlideDrawer } = useSlideDrawer();
  const { projectSecret } = useGlobalKolPlanningContext();
  const { currentPage } = useCurrentPage();
  const hideDateFilter = currentPage([]);
  const hideSearchFilter = currentPage(ROUTE.globalKolPlanningUrl);
  const showOnOffTopicFilter =
    currentPage([`/${ROUTE.kolProfile}`]) &&
    socialProjects.find(proj => proj.projectID === projectId)?.showOffTopic;
  const [topicName, setTopicName] = useState('On-Topic scientific content');
  const { filterMenu } = UpdateFilters({
    close: () => document.dispatchEvent(new MouseEvent('click')),
  });

  const {
    updatedFiltersArray,
    hiddenFilters,
    resetFilter,
    fetchGkpFilterReferences,
    currentSavedSearch,
    removeSavedSearch,
    conversationFilters,
  } = useFilters();

  useEffect(() => {
    if (projectSecret) {
      fetchGkpFilterReferences({ secret: projectSecret });
    }
  }, [projectSecret, fetchGkpFilterReferences]);

  const hasFilters = useCallback(() => {
    if (updatedFiltersArray?.filter(filter => !hiddenFilters.includes(filter.key))?.length > 0) {
      return true;
    }
    if (currentSavedSearch) {
      return true;
    }
    return false;
  }, [currentSavedSearch, updatedFiltersArray, hiddenFilters]);

  const handleResetFilters = useCallback(() => {
    if (currentSavedSearch) {
      removeSavedSearch();
    } else {
      resetFilter();
    }
    setTopicName('On-Topic scientific content');
  }, [resetFilter, currentSavedSearch, removeSavedSearch]);

  const getConcatenatedFilters = (filterValues, key) => {
    if (conversationFilters[key]) {
      return conversationFilters[key].reference;
    }

    if (typeof filterValues === 'string') {
      return filterValues;
    }

    if (Array.isArray(filterValues)) {
      if (filterValues.length < 4) {
        return filterValues.join(', ');
      }
      return `${filterValues.slice(0, 3).join(', ')}, +${filterValues.length - 3} More`;
    }
    const propNames = Object.getOwnPropertyNames(filterValues);

    return propNames
      .reduce((prev, curr) => {
        if (curr === 'lessThanOrEqualTo') {
          return [...prev, `Less than or Equal to: ${filterValues[curr]}`];
        }
        if (curr === 'greaterThanOrEqualTo') {
          return [...prev, `Greater than or Equal to: ${filterValues[curr]}`];
        }
        return [...prev, `${curr}: ${filterValues[curr]}`];
      }, [])
      .join(', ');
  };

  return (
    <div className={`${styles['search-bar-container']} ${className}`}>
      <div className={styles['filter-container']}>
        <div className={styles['filter-buttons-container']}>
          <Button
            buttonType="filter"
            size="small"
            icon="funnel"
            onClick={() =>
              openSlideDrawer({
                drawerTitle: 'Add filters',
                drawerContent: <FiltersMenu />,
              })
            }
          >
            Add filter
          </Button>
          {!hideDateFilter && <DateDropdown />}
          {showOnOffTopicFilter && (
            <OnOffTopicSelector topicName={topicName} setTopicName={setTopicName} />
          )}

          {!hideSearchFilter && <SearchDropDown showSaveSearch={showSaveSearch} />}
          {updatedFiltersArray.map(filter => {
            if (!hiddenFilters.includes(filter.key)) {
              return (
                <Button
                  buttonType="filter"
                  key={filter.key}
                  size="small"
                  secondaryAction={() => resetFilter(filter.key)}
                  onClick={() => {
                    openSlideDrawer({
                      drawerTitle: 'Add filters',
                      drawerContent: <FiltersMenu openFilter={filter?.id || filter.key} />,
                    });
                  }}
                  loading={!filter.label}
                >
                  {filter?.label}:{' '}
                  <strong>
                    {filter?.label?.toLowerCase().includes('date')
                      ? formatDate(filter.filterValues[0])
                      : getConcatenatedFilters(filter.filterValues, filter.key)}
                  </strong>
                </Button>
              );
            }

            return null;
          })}
          {hasFilters() && (
            <Button buttonType="warning" size="small" icon="trash" onClick={handleResetFilters}>
              Clear All
            </Button>
          )}
        </div>

        {showSaveSearch && (
          <div className={styles['filter-save-icon-container']}>
            {!currentSavedSearch && <SavedSearchModal />}
            {currentSavedSearch && (
              <DropDownMenu
                clickElement={
                  <div className="hoverable">
                    <KwelloTooltip position="left" content="Options">
                      <span>
                        <Icon name="menu" />
                      </span>
                    </KwelloTooltip>
                  </div>
                }
                menuItems={filterMenu}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MainFiltersContainer.propTypes = {
  showSaveSearch: PropTypes.bool,
  className: PropTypes.string,
};

MainFiltersContainer.defaultProps = {
  showSaveSearch: false,
  className: '',
};
