import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { useUser } from 'context/userContext';

import { generateHMAC } from 'utils/generateHMAC';
import useCurrentPage from 'utils/hooks/useCurrentPage';
import { isLocalhost } from 'utils/urlUtils';

export const useIntercom = () => {
  const { userDetails, modules, moduleProjects } = useUser();
  const { isGkp, isSocialMonitoring, isInsights, isInsightsCapture } = useCurrentPage();

  const initiateIntercom = useCallback(async () => {
    let hmac;
    const intercomLauncher = document.getElementsByClassName('intercom-launcher')?.[0];
    const intercomOpened = document.getElementsByClassName('intercom-app')?.[0];
    const { Intercom } = window;
    if (
      userDetails?.email &&
      !isLocalhost() &&
      !isMobile &&
      (isGkp || isSocialMonitoring || isInsights || isInsightsCapture)
    ) {
      const currentModule = modules?.find(module => module.isCurrent);
      const currentProject = currentModule
        ? moduleProjects(currentModule.key)?.find(
            project => project.projectID === currentModule.currentProject,
          )
        : undefined;
      window.intercomSettings = {
        app_id: 'y588ttna',
        custom_launcher_selector: '#intercom_help_center',
      };
      try {
        hmac = await generateHMAC(userDetails.userId, process.env.REACT_APP_SECRET_KEY);
      } finally {
        Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'y588ttna',
          email: userDetails?.email,
          user_id: userDetails?.userId,
          name: userDetails?.name,
          userClientName: userDetails.clientName,
          userClientId: userDetails.clientId,
          created_at: Date.now(),
          user_hash: hmac,
          moduleName: currentModule?.name,
          currentProjectId: currentModule?.currentProject,
          projectClientName: currentProject?.projectClientName,
          projectDisplayName: currentProject?.projectDisplayName,
          company: {
            company_id: userDetails.clientId,
            name: userDetails.clientName,
          },
        });
      }
    }
    if (intercomLauncher) {
      if (isGkp || isSocialMonitoring || isInsights || isInsightsCapture) {
        intercomLauncher.style.visibility = 'visible';
      } else {
        intercomLauncher.style.visibility = 'hidden';
      }
    }
    if (intercomOpened) {
      if (isGkp || isSocialMonitoring || isInsights || isInsightsCapture) {
        intercomOpened.style.visibility = 'visible';
      } else {
        intercomOpened.style.visibility = 'hidden';
      }
    }
  }, [
    userDetails,
    isGkp,
    isSocialMonitoring,
    isInsights,
    isInsightsCapture,
    modules,
    moduleProjects,
  ]);

  return { initiateIntercom };
};
