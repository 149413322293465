import { lazy, Suspense, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

import { useAuth } from 'context/authContext';
import { useLayout } from 'context/layoutContext';
import { useStatus } from 'context/statusContext';
import { useUser } from 'context/userContext';

import ROUTE from 'constants/route';
import { useCustomerIo } from 'utils/CustomerIo/useCustomerIo';
import { useFullStory } from 'utils/FullStory/useFullStory';
import useCurrentPage from 'utils/hooks/useCurrentPage';
import useTrackAction from 'utils/hooks/useTrackAction';
import { useIntercom } from 'utils/Intercom/useIntercom';
import { usePendo } from 'utils/Pendo/usePendo';

import ExternalLinkDiolog from 'common/ExternalLink/ExternalLinkDialog';
import CookieNotice from 'common/legal/CookieNotice';
import PrivacyPolicy from 'common/legal/PrivacyPolicy';
import TermsOfUse from 'common/legal/TermsOfUse';
import LoginMainPage from 'common/login/LoginMainPage';
import LogOutPage from 'common/login/LogOutPage';
import Mobile from 'common/login/Mobile';
import ModuleSelectionMenu from 'common/moduleSelectionMenu/ModuleSelectionMenu';
import { ToastNotification } from 'common/ToastNotification/ToastNotification';

import { LoadingPage, MaintenancePage, ModuleNotFound, PageNotFound, ProjectNotFound } from 'Pages';

const GkpRoute = lazy(() => import('../GkpRoute/GkpRoute'));
const CustomReportsRoute = lazy(() => import('../CustomReportsRoute/CustomReportsRoute'));
const ProtectedRoute = lazy(() => import('../ProtectedRoute/ProtectedRoute'));
const SocialMonitoringRoute = lazy(() => import('../SocialMonitoringRoute/SocialMonitoringRoute'));
const CaptureInsightsRoute = lazy(() => import('../CaptureInsightsRoute/CaptureInsightsRoute'));
const UserSettingsRoute = lazy(() => import('../UserSettingsRoute/UserSettingsRoute'));

const { error } = console;
// eslint-disable-next-line
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

export const Root = () => {
  const { status } = useStatus();
  const { msalCookies, isAuthenticated, accessToken, loading } = useAuth();
  const { inProgress } = useMsal();
  const { currentPage, isSocialMonitoring } = useCurrentPage();
  const { loadingUserDetails, uiPrefs, modules, userDetails } = useUser();
  const { pathname, search } = useLocation();
  const { hasSideBar } = useLayout();
  const pageView = useTrackAction('pageview');
  const { trackCio } = useCustomerIo();
  const { initiatePendo } = usePendo();
  const { initiateIntercom } = useIntercom();
  const { initiateFullStory } = useFullStory();
  const loginError = sessionStorage.getItem('loginError');
  const [intercomAvailable, setIntercomAvailable] = useState(false);
  const [fullStoryAvailable, setFullStoryAvailable] = useState(false);

  axios.defaults.baseURL = process.env.REACT_APP_DEFAULT_BASE_API_URL || '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  // These routes don't get caught in the following checks
  const noRedirect = [
    ...ROUTE.allowedUrls,
    ROUTE.maintenance,
    ROUTE.login,
    ROUTE.moduleNotFound,
    ROUTE.projectNotFound,
    ROUTE.logout,
    ROUTE.pageNotFound,
    ROUTE.userSettings,
  ];

  const allKwelloRoutes = [
    ROUTE.insights,
    ROUTE.sosvExperts,
    ROUTE.sosvScience,
    ROUTE.customReports,
    ROUTE.socialMonitoringUrl,
    ROUTE.globalKolPlanningUrl,
    'auth0',
    ROUTE.captureInsights,
  ];

  useEffect(() => {
    const checkIntercom = setInterval(() => {
      if (window.Intercom) {
        setIntercomAvailable(true);
        clearInterval(checkIntercom);
      }
    }, 1000);
    const checkFullStory = setInterval(() => {
      if (window.FS) {
        setFullStoryAvailable(true);
        clearInterval(checkFullStory);
      }
    }, 1000);

    return () => {
      clearInterval(checkIntercom);
      clearInterval(checkFullStory);
    };
  }, []);

  // On pageview, update App Insights to which userID viewed the page
  useEffect(() => {
    if (userDetails?.userId) {
      pageView();
      trackCio();
      initiatePendo();
      if (intercomAvailable) {
        initiateIntercom();
      }
      if (fullStoryAvailable) {
        initiateFullStory();
      }
    }
  }, [
    pageView,
    pathname,
    userDetails?.userId,
    trackCio,
    initiatePendo,
    initiateIntercom,
    initiateFullStory,
    intercomAvailable,
    fullStoryAvailable,
  ]);

  // If we don't have a status OR we have MSAL Cookies present, show loading spinner
  if (!loginError && (!status || msalCookies || loading)) {
    return <LoadingPage />;
  }

  // If we are in maintenance mode, redirect to maintenance page
  if (!currentPage(noRedirect) && status?.maintenanceMode) {
    return <Redirect to={ROUTE.maintenance} />;
  }

  // If we're on the maintenance page and no longer maintenance mode, redirect to root
  if (currentPage(ROUTE.maintenance) && !status?.maintenanceMode) {
    return <Redirect to="/" />;
  }

  // If we are not authenticated, redirect to login page
  if (!currentPage(noRedirect) && !isAuthenticated && inProgress === 'none' && !loading) {
    // keep any previous kwello url path to redirect to
    if (allKwelloRoutes.find(route => pathname.includes(route))) {
      localStorage.setItem('previousKwelloPath', pathname);
      localStorage.setItem('previousSearchValues', search);
    }

    return <Redirect to={ROUTE.login} />;
  }

  // If we are authenticated, but we don't have user details, show loading spinner
  if (!loginError && !currentPage(noRedirect) && (loadingUserDetails || modules.length === 0)) {
    return <LoadingPage />;
  }

  // If we don't have a module, redirect to the first module available or an outside link if set in storage
  if (
    !loginError &&
    (pathname === '/' ||
      pathname?.toLowerCase().includes('auth0') ||
      pathname.includes('help-center'))
  ) {
    const currentModule = modules.find(module => module.isCurrent) || modules[0];
    const previousKwelloPath = localStorage.getItem('previousKwelloPath');
    const previousSearch = localStorage.getItem('previousSearchValues') || '';
    return (
      <Redirect
        to={previousKwelloPath ? `${previousKwelloPath}${previousSearch}` : currentModule.navLink}
      />
    );
  }

  // If we are on a module that doesn't exist, redirect to module not found page
  if (
    !loginError &&
    !currentPage(noRedirect) &&
    !modules.find(module => pathname.includes(module.navLink)) &&
    !pathname.includes('auth0') &&
    !pathname.startsWith(ROUTE.captureInsights)
  ) {
    return <Redirect to={ROUTE.moduleNotFound} />;
  }

  if (
    (isMobileOnly && !ROUTE.mobileAllowedUrls.find(url => pathname.startsWith(url))) ||
    (isMobileOnly && ROUTE.mobileModules.find(module => module === pathname))
  ) {
    if (pathname.includes(ROUTE.socialMonitoringUrl)) {
      return <Redirect to={`${ROUTE.socialMonitoringUrl}/${ROUTE.feed}`} />;
    }
    if (modules?.find(module => module.key === 'insightsCapture')) {
      return <Redirect to={`${ROUTE.captureInsights}/${ROUTE.landing}`} />;
    }
    if (modules?.find(module => module.navLink === ROUTE.socialMonitoringUrl)) {
      // if user has social monitoring modules access redirect to social
      return <Redirect to={`${ROUTE.socialMonitoringUrl}/${ROUTE.feed}`} />;
    }
    return <Redirect to={ROUTE.mobile} />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Helmet style={[{ cssText: uiPrefs.UiThemeCss }]} />

      <Switch>
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/cookies" component={CookieNotice} />
        <Route exact path="/terms" component={TermsOfUse} />
        <Route exact path="/mobile" component={Mobile} />
        <Route path={ROUTE.maintenance} component={MaintenancePage} />
        <Route path={ROUTE.login} component={LoginMainPage} />
        <Route path={ROUTE.projectNotFound} component={ProjectNotFound} />
        <Route path={ROUTE.pageNotFound} component={PageNotFound} />
        <Route path={ROUTE.moduleNotFound} component={ModuleNotFound} />
        <ProtectedRoute path={ROUTE.userSettings} component={UserSettingsRoute} />
        <ProtectedRoute path={ROUTE.logout} component={LogOutPage} />
        <ProtectedRoute
          path={`${ROUTE.socialMonitoringUrl}/:projectId?`}
          component={SocialMonitoringRoute}
        />
        <ProtectedRoute path={`${ROUTE.globalKolPlanningUrl}/:projectId?`} component={GkpRoute} />
        <ProtectedRoute
          path={[
            `${ROUTE.insights}/:projectId?`,
            `${ROUTE.sosvExperts}/:projectId?`,
            `${ROUTE.sosvScience}/:projectId?`,
            `${ROUTE.customReports}/:projectId?`,
          ]}
          component={CustomReportsRoute}
        />
        <ProtectedRoute
          path={`${ROUTE.captureInsights}/:projectId?`}
          component={CaptureInsightsRoute}
        />
      </Switch>
      {hasSideBar && !isSocialMonitoring && <ModuleSelectionMenu />}
      <ToastNotification />
      <ExternalLinkDiolog />
    </Suspense>
  );
};
